<script setup lang="ts"></script>

<template>
  <div class="common">
    <div class="title">Привязка карты</div>
    <div class="content">
      Ваша карта будет привязана на платформе в течение одной минуты!
    </div>
    <div class="description">
      Если вы не обнаружили привязку карты - попробуйте обновить страницу!
    </div>
  </div>
</template>

<style scoped lang="scss">
.common {
  padding: 24px;
  .title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .content {
    font-size: 14px;
    margin-bottom: 24px;
  }
  .description {
    font-size: 14px;
  }
}
</style>
